import * as React from "react";
import type { SVGProps } from "react";
export const SvgLolDiscordIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 18 18" {...props}>
    <path
      fill="#5C5C8E"
      d="M14.478 4.003A13 13 0 0 0 11.222 3a8 8 0 0 0-.414.844 12.4 12.4 0 0 0-3.606 0A10 10 0 0 0 6.788 3a12.8 12.8 0 0 0-3.256 1.003c-2.062 3.04-2.619 6.01-2.34 8.938 1.369.995 2.69 1.6 3.988 1.998.319-.43.605-.892.852-1.377a8.4 8.4 0 0 1-1.345-.645 5 5 0 0 0 .326-.254c2.595 1.186 5.405 1.186 7.968 0 .112.087.215.175.327.254q-.643.383-1.346.645c.247.486.534.947.852 1.377a13.2 13.2 0 0 0 3.988-1.998c.343-3.39-.541-6.335-2.324-8.938M6.39 11.134c-.78 0-1.417-.708-1.417-1.575 0-.868.621-1.576 1.417-1.576.788 0 1.433.708 1.417 1.576 0 .867-.629 1.575-1.417 1.575m5.23 0c-.78 0-1.417-.708-1.417-1.575 0-.868.621-1.576 1.417-1.576.788 0 1.433.708 1.417 1.576 0 .867-.62 1.575-1.417 1.575"
    />
  </svg>
);
